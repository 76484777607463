import * as React from 'react';
import {PageProps} from 'gatsby';
import * as JsSearch from 'js-search';
import {IUpdateMenuItemsProps, updateMenuItems} from '../state/menu-items.slice';
import {connect} from 'react-redux';
import {mapStateToProps} from '../state/store';
import {IGraphQLMenuEntry} from '../../graphql.types';

interface ISearchServerData {
    content: string;
    menuItems: Array<IGraphQLMenuEntry>;
}
type IManualProps = PageProps<any, ISearchServerData>;
interface ISearchState {
    searchQuery: string;
    searchResults: Array<any>;
}
class SearchContent extends React.Component<IManualProps & IUpdateMenuItemsProps, ISearchState> {
    private readonly availableSearchEntries: Array<any> = [];

    constructor(props: IManualProps & IUpdateMenuItemsProps) {
        super(props);

        this.availableSearchEntries = JSON.parse(props.pageContext.content);
        this.state = {
            searchQuery: '',
            searchResults: [],
        };
    }

    public componentDidMount(): void {
        this.props.updateMenuItems(this.props.pageContext.menuItems);

        const search = new JsSearch.Search('id');
        search.indexStrategy = new JsSearch.ExactWordIndexStrategy();
        search.sanitizer = new JsSearch.LowerCaseSanitizer();
        search.addIndex('title');
        search.addIndex('content');
        search.addDocuments(this.availableSearchEntries);

        const params = new URLSearchParams(location.search);
        const searchQuery: string = params.get('term') || '';
        if (searchQuery.length >= 3) {
            this.setState({
                searchQuery,
                searchResults: search.search(searchQuery),
            });
        }
    }

    public render(): React.ReactNode {
        return (
            <section style={{padding: '16px'}}>
                <h1 style={{marginTop: 0}}>{this.state.searchResults.length} Suchergebnisse für {this.state.searchQuery}</h1>
                {this.state.searchResults.map((entry: any, index: number) => (
                    <div key={index}>
                        <h2><a href={entry.slug}>{entry.title}</a></h2>
                        <p>
                            {entry.content.substr(0, 320)}
                            {entry.content.length >= 320 ? '…' : ''}
                            <a href={entry.slug}>Artikel ansehen</a>
                        </p>
                    </div>
                ))}
            </section>
        );
    }
}

export default connect(mapStateToProps, {updateMenuItems})(SearchContent);
